import React from 'react';
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image';

function Banner () { 

  const data = useStaticQuery(
    graphql`
      query {
        author: file(relativePath: { eq: "image/author.jpg" }) {
          childImageSharp {
            fluid(sizes: "(min-width: 720px) 50vw, 720px") {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gradient: file(relativePath: { eq: "image/gradient.png" }) {
          childImageSharp {
            fluid(maxWidth: 720, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tarmacLogo: file(relativePath: { eq: "image/tarmac-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );

  return (
    <div className="flex flex-nowrap" style={{height: "44vw"}}>
      <Img 
        className="w-1/2" 
        fluid={data.author.childImageSharp.fluid} 
        imgStyle={{
          objectPosition: "top",
        }}
      />
      <BackgroundImage
        Tag="section"
        fluid={data.gradient.childImageSharp.fluid}
        className="w-1/2"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      > 
        <div className="h-full flex items-center justify-between">
          <div className="h-2/5 bg-white" style={{width: "0.75%"}}></div>
          <Img 
            className="w-1/2" 
            fluid={data.tarmacLogo.childImageSharp.fluid} 
          />
          <div/>
        </div>
      </BackgroundImage>
    </div>
  );
}

export default Banner;